import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // URLs that should not include the Authorization header
        const s3UrlPattern = /https:\/\/s3\.(.+)\.amazonaws\.com/; 

        if (req.url.indexOf(".json") === -1 && req.url.indexOf("docs.") === -1) {
            let auth = localStorage.getItem("accessToken");

            // Check if the request is for S3
            if (s3UrlPattern.test(req.url)) {
                // console.log(`S3 API request detected: ${req.url}`);
                // Clone the request without Authorization header
                const authReq = req.clone({
                    headers: req.headers.delete("Authorization"), // Remove Authorization
                });
                return next.handle(authReq);
            } else {
                // For other APIs, include the Authorization header
                // console.log(`Adding Authorization header: ${req.url}`);
                const authReq = req.clone({
                    headers: new HttpHeaders({
                        "Authorization": `Bearer ${auth}`,
                        // "x-api-key": environment.API_VERIFICATION_KEY, // Uncomment if needed
                    }),

                });
                return next.handle(authReq);
            }
        } else {
            // If the request URL ends with `.json` or contains `docs.`
            const authReq = req.clone();
            return next.handle(authReq);
        }
    }
}
