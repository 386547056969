import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule
} from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
// import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
// import { ProfileModule } from 'app/main/profile/profile.module';
import { environment } from 'environments/environment';
import 'hammerjs';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ToastrModule } from 'ngx-toastr';
// import { AutoSparePartsModule } from './main/auto-spare-parts/auto-spare-parts.module';
// import { CatalogueModule } from './main/catalogue/catalogue.module';
import { HttpInterceptorService } from './shared/http-interceptor.service';
// import { MessagingService } from './shared/messaging.service';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { CommonModule } from '@angular/common';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

// import { AspCarCompatibilityPopupComponent } from './asp-car-compatibility-popup/asp-car-compatibility-popup.component';
// import { AspMyRequestComponent } from './asp-my-request/asp-my-request.component';
// import { AspPaymentComponent } from './main/auto-spare-parts/asp-payment/asp-payment.component';
// import { AspHomeComponent } from './main/auto-spare-parts/asp-home/asp-home.component';

import { LoginModule } from './main/login/login.module';
import { ListPageModule } from './main/list-page/list-page.module';

import { DetailsPageModule } from './main/details-page/details-page.module';
import { S3ImageUploadService } from './shared/s3-image-upload.service';



export function httpTranslateLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }

const appRoutes: Routes = [
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/login',
    },

    {
        path: 'login',
        pathMatch: 'full',
        redirectTo: '/login',
    },



    // {
    //     path: 'login',
    //     loadChildren: () =>
    //         import('app/main/login/login.module').then(
    //             (m) => m.LoginModule
    //         ),
    //     runGuardsAndResolvers: 'always',
    // },

    // {
    //     path: 'auto-spare-parts',
    //     loadChildren: () =>
    //         import('app/main/auto-spare-parts/auto-spare-parts.module').then(
    //             (m) => m.AutoSparePartsModule
    //         ),
    //     runGuardsAndResolvers: 'always',
    // },

];

@NgModule({
       declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: "legacy" }),

        // TranslateModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: httpTranslateLoaderFactory,
            deps: [HttpClient]
        }
        }),
        ToastrModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        // Material
        MatSnackBarModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        GooglePlaceModule,
        MatBottomSheetModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        CommonModule,

        // App modules
        LayoutModule,
        // ProfileModule,
        // AutoSparePartsModule,
        // CatalogueModule,
        AgmCoreModule.forRoot({
            apiKey: "AIzaSyA-FwkEuPz6efVsc-NszVSD-Fgo4-gXcvA",
            libraries: ["places"],
            region: "IN",
        }),
        MatGoogleMapsAutocompleteModule,
        LoginModule,
        ListPageModule,
        DetailsPageModule,



        
    ],
    providers: [
        S3ImageUploadService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
