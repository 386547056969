import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { httpTranslateLoaderFactory } from 'app/app.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ApplicationPipesModule } from '../applicationPipesModule.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatNativeDateModule } from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { LayoutModule } from '@angular/cdk/layout';
import { ListPageComponent } from './list-page.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';


const routes: Routes = [
  {
      path:'order-list',
      component: ListPageComponent,
  },
];

@NgModule({
  declarations: [ListPageComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FuseSharedModule,
    MatCardModule,
    // MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    FuseSharedModule ,
    MatGridListModule,
    MatInputModule,
    MatDatepickerModule,
    // MatGridListModule,
    MatNativeDateModule ,
    // MatStepperModule,
    CdkStepperModule,
    MatProgressBarModule,
    // MatProgressSpinnerModule,
    ApplicationPipesModule,
    LayoutModule,
    // MatRadioModule,
    // MatAutocompleteModule,
    HttpClientModule,
    FlexLayoutModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    TranslateModule.forRoot({
      loader: {
        
          provide: TranslateLoader,
          useFactory: httpTranslateLoaderFactory,
          deps: [HttpClient]
      }
      })
    ],  
    exports: [ListPageComponent],
  })
export class ListPageModule { }
