<div class="main-wrapper">
  <div class="container">


    <!-- Loading Overlay -->
    <!-- <div *ngIf="loading" class="loading-overlay">
      <div class="spinner">
        <mat-progress-spinner
          mode="indeterminate"
          diameter="50"
          color="accent"
        ></mat-progress-spinner>
        <p>Uploading, please wait...</p>
      </div>
    </div> -->

    <!-- Vehicle Details Section -->
    <mat-grid-list [cols]="gridCols" rowHeight="100px" gutterSize="16px">
      <mat-grid-tile rowspan="1" [colspan]="dynamicColspan">
        <div class="vehicle-details">
          <img *ngIf="vehicleDetails.make; else noLogo"
            [src]="'https://cube.getpitstop.com/assets/img/car_make/' + vehicleDetails.make.replace(' ', '').toLowerCase() + '.png'"
            alt="Vehicle Logo"
            class="vehicle-logo">
          <ng-template #noLogo>
            <mat-icon class="logo-image" style="font-size:35px;">directions_car</mat-icon>
          </ng-template>
          <div class="vehicle-info">
            <ng-container *ngIf="vehicleDetails.make === ''; else vehicleDetailsContent">
              <p class="vechile-variant-universal">Universal</p>
            </ng-container>
            <ng-template #vehicleDetailsContent>
              <p class="vechile-variant" style="font-weight:bold">{{ vehicleDetails.model }}</p>
              <p class="vechile-regno">{{ vehicleDetails.variantKey }}, [{{ vehicleDetails.year }}]</p>
              <p class="vehicle-model">{{ vehicleDetails.registrationNumber }}</p>
            </ng-template>
          </div>
          
          <div>
            <p [ngClass]="{
              'cancelled': vehicleDetails.status == '0',
              'created': vehicleDetails.status == '1',
              'confirmed': vehicleDetails.status == '2',
              'ready-to-pick': vehicleDetails.status == '3',
              'dispatched': vehicleDetails.status == '4'
            }">{{ getStatusName(vehicleDetails.status) }}</p>
            <p class="date-time">{{ vehicleDetails.orderCreated | date:'dd MMM yyyy, hh:mm a' }}</p>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <!-- Data Table Section -->
    <h2>Spare Items</h2>
    <div class="scroller" >
    <table mat-table class="data-table" >
      <thead class="th">
        <tr class="tr">
          <th class="th1">Name</th>
          <th>Part Number</th>
          <th>Brand</th>
          <th>Qty</th>
          <th>MRP</th>
          <th>Cost Price (with Tax)</th>
        </tr>
      </thead>
      <tbody>
        <!-- {{tableData | json}} -->
        <tr *ngFor="let element of tableData">
          <td>{{ element.name }}</td>
          <td>{{ element.partNumber }}</td>
          <td>{{ element.brand }}</td>
          <td>{{ element.qty }}</td>
          <td>{{ element.mrp }}</td>
          <td>{{ element.costPrice }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr style="font-weight: bold;">
            <td colspan="5" >TOTAL</td>
            <td>₹{{invoiceTotal}}</td>
        </tr>
    </tfoot>
    </table>
  </div>

    <mat-grid-list [cols]="gridCols" rowHeight="fit" gutterSize="16px">
      <mat-card class="upload-card">
        <!-- Add Image Button in the Top-Right Corner -->
        <div class="card-header">
          <p class="add-img" style="font-size:12px">Add Images</p>
          <mat-icon class="camera" (click)="fileInput.click()">photo_camera</mat-icon>
          <input
            type="file"
            #fileInput
            (change)="onFileSelected($event)"
            accept="image/*"
            multiple
            style="display: none;"
          />
        </div>

        <!-- Display Selected Image Previews -->
        <div class="image-preview-container" >
          <div *ngIf="imagePath?.length === 0" class="no-image-text">
            <p>No image selected</p>
          </div>
          <div class="image-wrapper" *ngFor="let imageUrl of imagePath">
            <!-- Cross Icon -->
            <button mat-icon-button class="delete-icon" (click)="removeImage(imageUrl)">
              <mat-icon style="margin-top:-35px;margin-left: -8px;" color="warn">close</mat-icon>
            </button>

              <img [src]="imageUrl" alt="Selected Image" class="image-preview" (click)="openImage(imageUrl)" />

             </div>
        </div>
      </mat-card>

      <!-- Mat Card for Upload Invoice -->
      <mat-card class="upload-invoice-card">
        <div class="card-header1" style="display:flex;">
          <p class="add-img1" style="font-size:12px;width: 90%;">Retailer Invoice</p>
          <div style="width: 10%;" *ngIf="vehicleDetails?.status <= 3">
          <mat-icon class="camera" (click)="showUploadPopup = true">cloud_upload</mat-icon>
        </div>
          <input
            type="file"
            #invoiceInput
            accept="application/pdf"
            style="display: none;"
          />
        </div>

        <!-- Display Selected Invoice Name -->
      <div class="invoice-preview-container">
          <div *ngIf="!invoicePath" class="no-invoice-text">
            <p>No invoice selected</p>
          </div>
<div *ngIf="invoicePath && invoicePath.length > 0" class="invoice-wrapper">
  <div *ngIf="invoiceNumber && invoiceNumber.length > 0">
    <div *ngFor="let invoiceNo of (invoiceNumber[0] ? invoiceNumber[0].split(',') : []); let i = index">
      <mat-icon class="pdf-icon" color="warn">picture_as_pdf</mat-icon>
      <span class="invoice-name" 
            (click)="openInvoice(invoicePath && invoicePath.length > 0 ? invoicePath[0].split(',')[i].trim() : '')">
        Retailer_Invoice_{{ invoiceNo.trim() }}
      </span>
      <button 
      mat-icon-button 
      *ngIf="vehicleDetails?.status <= 3" 
      (click)="onDeleteInvoice(invoicePath && invoicePath.length > 0 ? invoicePath[0].split(',')[i].trim() : '', invoiceNo.trim())">
      <mat-icon style="background-color: lightgray; margin-top: -10px;">delete</mat-icon>
    </button>
    </div>
  </div>
  
</div>

          
          
          
        </div>
      </mat-card>

    </mat-grid-list>

    <!-- Upload Invoice Popup -->
    <div *ngIf="showUploadPopup" class="popup-overlay">
      <div class="popup">
        <div class="cancel">
          <button style="float: right;margin-top: -3%;" mat-icon-button color="warn" (click)="showUploadPopup=false">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <h2>Upload Invoice</h2>
        <form [formGroup]="invoiceForm">
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Invoice Date<span class="required-asterisk">*</span></mat-label>
            <input matInput type="date" formControlName="invoiceDate" />
            <mat-error *ngIf="invoiceForm.get('invoiceDate')?.hasError('required')">
              Invoice Date is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Invoice Number<span class="required-asterisk">*</span></mat-label>
            <input matInput type="text" formControlName="invoiceNumber" />
            <mat-error *ngIf="invoiceForm.get('invoiceNumber')?.hasError('required')">
              Invoice Number is required.
            </mat-error>
          </mat-form-field>
          <input
            type="file"
            (change)="onPdfSelected($event)"
            formControlName="invoiceFile"
            class="file-input"
          />
          <mat-error *ngIf="invoiceForm.get('invoiceFile')?.hasError('required') && !uploadedInvoiceName">
            Invoice file is required.
          </mat-error>
          <div class="popup-actions">
            <button mat-raised-button class="upload" color="primary" #pdfInput (click)="uploadInvoice()">Upload</button>
          </div>
        </form>
      </div>
    </div>
     <!-- Conditionally Render Buttons -->
      <div>
        <button
          mat-raised-button
          color="accent"
          class="create-payment-link"
          *ngIf="vehicleDetails.status == '1'"
          (click)="showUploadPopup = true"
          
        >
          Upload Invoice
        </button>
        <button
          mat-raised-button
          color="accent"
          class="create-payment-link"
          *ngIf="vehicleDetails.status == '2'"
          (click)="onMarkReadyToShip()"
          
        >
          Ready to Pick
        </button>
        <button
          mat-raised-button
          color="accent"
          class="create-payment-link"
          *ngIf="vehicleDetails.status == '3'"
          (click)="onMarkDispatched()"
          
        >
          Dispatched
        </button>
      </div>
  </div>


 
</div>
