import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from '../globals';
@Injectable({
  providedIn: 'root',
})
export class ListPageService {
  constructor(private http: HttpClient) {
  }
  

  getOrderList(vendorKey,pageIndex: number = 0, pageSize: number = 50) {
   
    const paramData = {
      vendorKey: vendorKey,
    };
    const params = new HttpParams()
    .set('page', pageIndex.toString())
    .set('size', pageSize.toString())

    return this.http.post(
        `${GlobalVariable.BASE_API_URL_VENDOR}/order/getAllOrders`,paramData,{ params });
    }
}
