import { Component, ComponentFactoryResolver, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { AppComponent } from 'app/app.component';
import { navigation } from 'app/navigation/navigation';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {  NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';



export interface SearchOrdersData {
    orderId: string;
    registration: string;
    fuelType: string;
    vehicle: string;
    orderStartTime: any;
    orderEndTime: any;
    grandTotal: string;
    status: string;
}

@Component({
    host: {
        '(document:click)': 'onClick($event)'
      },
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
  
    loading = false;
  
  
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
  
    navigation: any;
   
    userData: any = { name: '', img: '' };
    loginData: any;
    timezone: any;
    format: any;
    moment = moment();
    
    showCreateOrder = false;
    offset = 1;
    notificationData: any;
    responseData: any;
    totalNotification: any;
    cartCount = 0;
    showNotifaction = false;
    notificationId: any;
    deactivateResult: any;
    showPaginations = false;
    showNotification = false;
    showPrevButton = true;
    noNotification = false;
    isInputVisible = false;
    translateImg:any = './assets/icons/language-change-icon.png';
    langSwitchImg = [{"en":"./assets/icons/en.png", "hi": "https://cube.getpitstop.com/prodimages/pitstop_delhi_icon.png", "ka": "https://cube.getpitstop.com/prodimages/pitstop_bengaluru_icon.png"}];
    searchText = ''
    suggestions: string[] = [];
    sidebarOpen: boolean = false;
    totalAmount = 0;
    walletInfoData: any = {};
    isMobile: boolean = false;
    isDrawerVisible = false;
    knowMoreHeading: string = '';
    knowMorePoints: string[] = [];
    selectedGarage: string;
    pageHeaders: { title: string; values: string } | null = null;
    selectedSuggestions: Set<string> = new Set<string>();
    
    // Private
    private _unsubscribeAll: Subject<any>;
    @ViewChild('TABLE', { static: false }) table: ElementRef;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    suggestionType: any;
    suggestionAll: any=[];
    @ViewChild('dropdown') dropdown;

    loginService: any;
  
    hideHamburgerMenu: boolean = false;
    disableHamburgerMenu: boolean = false;
    
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        public router: Router,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        // public loginService: LoginService,
        private _formBuilder: FormBuilder,
        // private soService: SearchOrdersService,
        private appComponent: AppComponent,
        private _fuseNavigationService: FuseNavigationService,
        private _eref: ElementRef,
        public translate: TranslateService,
        private resolver: ComponentFactoryResolver,
        
        private breakpointObserver: BreakpointObserver
    ) {

       // Detect route changes
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
    //   console.log('Current route:', event.url);

      // Disable the hamburger menu for specific route
      this.disableHamburgerMenu = event.url.startsWith('/order-details');
    //   console.log("jdj---",this.disableHamburgerMenu)
    });
        this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });


        this.navigation = navigation;
        this._unsubscribeAll = new Subject();


        // Register translation languages
        translate.addLangs(['en', 'hi']);
        // Set default language
        translate.setDefaultLang('en');

    }



    getHighlightedText(text: any, search: any): string {
        if (typeof text !== 'string' || typeof search !== 'string') {
            // Handle cases where text or search is not a string
            return text;
        }
    
        if (!search) {
            return text; // Return original text if no search term
        }
    
        const parts = text.split(new RegExp(`(${search})`, 'gi'));
        return parts.map(part => part.toLowerCase() === search.toLowerCase()
            ? `<span class="highlight">${part}</span>`
            : part
        ).join('');
    }
    onSubmit() {
        this.loading = true;

        // this.getSearchOrdersData(this.f.condition.value, this.f.query.value);
    }

    onClick(event) {
        if (!this._eref.nativeElement.contains(event.target)){
            this.showNotifaction = false;

    }
    }


    
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loading = true;         
    }
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
  
    logout() {
        this.loginService.logout();

    }
    openSidebar(): void {     
        this.sidebarOpen = true;     
      }
    
      closeSidebar(): void {
        this.sidebarOpen = false;
        // this.closeDrawer();
      }
}